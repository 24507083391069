import React from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import SearchIcon from '@mui/icons-material/Search';
import FavoriteIcon from '@mui/icons-material/Favorite';
import PersonIcon from '@mui/icons-material/Person';
import SpeedOutlinedIcon from '@mui/icons-material/SpeedOutlined';
import ShutterSpeedOutlinedIcon from '@mui/icons-material/ShutterSpeedOutlined';
import { doc, getDoc } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { db } from '../firebase';

function FooterNav() {
    const location = useLocation();
    const navigate = useNavigate();
    const [value, setValue] = React.useState(location.pathname);
    const [isLoggedIn, setIsLoggedIn] = React.useState(false);
    const [isProfileSetup, setIsProfileSetup] = React.useState(false);

    // Get the actual location and update footer to show active page icon
    React.useEffect(() => {
        setValue(location.pathname);
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                setIsLoggedIn(true);
                // Check if the user's profile is set up in firestore
                const userDocRef = doc(db, 'users', user.uid);
                const userDoc = await getDoc(userDocRef);
                if (userDoc.exists()) {
                    const userProfile = userDoc.data();
                    // Assuming profile is set up if it contains a username
                    setIsProfileSetup(!!userProfile.username);
                } else {
                    setIsProfileSetup(false);
                }
            } else {
                setIsLoggedIn(false);
                setIsProfileSetup(false);
            }
        });

        return () => unsubscribe();
    }, [location]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        navigate(newValue);
    };

    // Do not show footer is user is not logged in

    if (!isLoggedIn || !isProfileSetup ) return null;

    return (
        <BottomNavigation
            value={value}
            onChange={handleChange}
            showLabels
            sx={{
                position: 'fixed',
                bottom: 0,
                width: '100%',
                backgroundColor: '#865D36',
                color: '#FFFFF0'
            }}
            >
            <BottomNavigationAction label="Home" value="/" icon={<HomeIcon sx={{ color: value === '/' ? '#DC143C' : '#FFD700' }} />}
                sx={{ '& .MuiBottomNavigationAction-label': { color: "#FFFFF0"} }}
                                    component={ Link } to="/"/>
            <BottomNavigationAction label="Link" value="/link" icon={<SearchIcon sx={{ color: value === '/link' ? '#DC143C' : '#FFD700' }} />}
                sx={{ '& .MuiBottomNavigationAction-label': { color: "#FFFFF0"} }}
                                    component={ Link } to="/link"/>
            <BottomNavigationAction label="Matches" value="/matches" icon={<FavoriteIcon sx={{ color: value === '/matches' ? '#DC143C' : '#FFD700' }} />}
                sx={{ '& .MuiBottomNavigationAction-label': { color: "#FFFFF0"} }}
                                    component={ Link } to="/matches"/>
            <BottomNavigationAction label="Speed" value="/speed" icon={<ShutterSpeedOutlinedIcon sx={{ color: value === '/speed' ? '#DC143C' : '#FFD700' }} />}
                sx={{ '& .MuiBottomNavigationAction-label': { color: "#FFFFF0"} }}
                                    component={ Link } to="/speed"/>
            <BottomNavigationAction label="Profile" value="/profile" icon={<PersonIcon sx={{ color: value === '/profile' ? '#DC143C' : '#FFD700' }} />}
                sx={{ '& .MuiBottomNavigationAction-label': { color: "#FFFFF0"} }}
                                    component={ Link } to="/profile"/>
        </BottomNavigation>
    );
}

export default FooterNav;