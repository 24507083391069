import React, { useState } from 'react';
import { Box, Typography, TextField, Button, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { getAuth } from 'firebase/auth';
import { useNavigate } from "react-router-dom";
import {getFirestore, doc, updateDoc, setDoc, getDoc} from 'firebase/firestore';
import { auth, db } from '../firebase';

// Sample data for dropdowns
const cities = ["Auckland","Adelaide", "Brisbane", "Christchurch", "Dunedin", "Hamilton",
     "Hobart", "Melbourne", "Perth", "Rotorua", "Sydney", "Tauranga", "Wellington"];
const regions = ["North Island", "South Island", "Auckland Region", "Wellington Region", "Waikato", "Bay of Plenty", "Otago", "New South Wales",
    "Victoria", "Queensland", "South Australia", "Western Australia", "Tasmania"];
const personalityTypes = ["Introverted", "Extroverted"];
const partyOptions = ["Yes", "No"];
const petOptions = ["Yes", "No"];
const favoriteAnimals = ["Dog", "Cat", "Bird", "Fish", "Other"];
const hobbies = ["Reading", "Traveling", "Sports", "Music", "Other"];
const descriptions = ["Ambitious", "Easygoing", "Creative", "Adventurous"];
const heightPreferences = ["Taller", "Same Height +", "Shorter", "Same height -", "Don't Care"];
const genderOptions = ["Female", "Male", "Non-Binary", "Transgender", "Genderqueer/Non-Conforming"];
const sexualPreferences = ["Straight", "Lesbian", "Gay", "Bisexual", "Asexual", "Pansexual"];

function ProfileSetup() {
    // State to hold the profile data
    const [profile, setProfile] = useState({
        username: '',
        age: '', // Will be a number soon
        city: '',
        region: '',
        commonInterests: ['', '', ''],
        heightFeet: '',
        heightInches: '',
        heightPreference: '',
        personalityType: '',
        isPartyGoer: '',
        likesPets: '',
        favoriteAnimal: '',
        gender: '',
        sexualPreference: '',
        additionalQuestions: {
          introverted: '',
          hobby: '',
          bestDescribe: '',
        }
    });

    //Initialize useNavigate for navigation
    const navigate = useNavigate();

    // Handle change for all inputs except common interests and additional questions
    const handleChange = (event) => {
        const { name, value } = event.target;
        setProfile({ ...profile,
            [name]: name === 'age' ? Number(value) : value  // Convert age to a number
        });
    };

    // Handle change for additional questions
    const handleAdditionalChange = (event) => {
        const { name, value } = event.target;
        setProfile({
            ...profile,
            additionalQuestions: { ...profile.additionalQuestions, [name]: value }
        });
    };

    // Handle change for common interests inputs
    const handleCommonInterestChange = (index, event) => {
        const newInterests = profile.commonInterests.slice();
        newInterests[index] = event.target.value;
        setProfile({ ...profile, commonInterests: newInterests });
    };

    // Handle Form submission
    const handleSubmit = async () => {

        const user = auth.currentUser; // Get the current User
        if (user) {
            try {
                const userRef = doc(db, 'users', user.uid); // Reference to the user's document in Firestore
                const userSnap = await getDoc(userRef); // Check if doc exists

                // Add UID to profile object
                const updatedProfile = {
                    ...profile,
                    uid: user.uid,
                    remainingRizzUpRequests: 2, // Set initial rizzUp requests
                    remainingSpeedDateRequests: 2, // Set initial speedDate requests
                    lastRizzUpReset: new Date(), // Set the current date for rizzUp reset
                    lastSpeedDateReset: new Date() // Set the current date for speedDate reset
                };

                if (userSnap.exists()) {
                    // Update the user's profile in Firestore
                    await updateDoc(userRef, {profile: updatedProfile}); // Updates thes user's profile in Firestore
                    console.log('ProfileSetup: Profile updated Successfully');
                } else {
                    // Create the user's profile in Firestore if it doesn't exist
                    await setDoc(userRef, { profile: updatedProfile });
                    console.log('ProfileSetup: Profile created successfully');
                }

                // Navigate to profile picture upload page
                navigate('/profile-picture-upload');
            } catch (error) {
                console.error('ProfileSetup: Error updating Profile: ', error);
            }
        }
    };

    return (
        <Box sx={{ p: 4, maxWidth: 600, mx: 'auto' }}>
            <Typography variant="h4" align="center" gutterBottom>
                Set Up Your Profile
            </Typography>

            {/* Username */}
            <Typography variant="h6" gutterBottom>
                Username <span style={{ color: 'red' }}>*</span>
            </Typography>
            <TextField
                label="Your username"
                name="username"
                value={profile.username}
                onChange={handleChange}
                inputProps={{ maxLength: 18, minLength: 3 }}
                fullWidth
                margin="normal"
                helperText="Username must be between 3 and 18 characters."
            />

             {/* Age */}
            <Typography variant="h6" gutterBottom>
                Age (must be 18+) <span style={{ color: 'red' }}>*</span>
            </Typography>
            <TextField
                label="Your age"
                name="age"
                type="number"
                inputProps={{ min: 18 }}
                value={profile.age}
                onChange={handleChange}
                fullWidth
                margin="normal" />

            {/* City Selection */}
            <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>City <span style={{ color: 'red' }}>*</span></InputLabel>
                <Select
                    name="city"
                    value={profile.city}
                    onChange={handleChange}>
                    {cities.map((city) => (
                        <MenuItem key={city} value={city}>{city}</MenuItem>
                    ))}
                </Select>
            </FormControl>

            {/* Region selection */}
            <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Region <span style={{ color: 'red' }}>*</span></InputLabel>
                <Select
                  name="region"
                  value={profile.region}
                  onChange={handleChange}
                >
                  {regions.map((region) => (
                    <MenuItem key={region} value={region}>{region}</MenuItem>
                  ))}
                </Select>
            </FormControl>

            {/* Common interests */}
           <Typography variant="h6" gutterBottom>
             Common Interests <span style={{ color: 'red' }}>*</span>
           </Typography>
           {profile.commonInterests.map((interest, index) => (
            <TextField
              key={index}
              label={`Interest ${index + 1}`}
              value={interest}
              onChange={(e) => handleCommonInterestChange(index, e)}
              fullWidth
              margin="normal"
            />
          ))}


            {/* Height selection */}
            <Typography variant="h6" gutterBottom>
                Height (Private) <span style={{ color: 'red' }}>*</span>
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2}}>
                <TextField
                    label="Feet"
                    name="heightFeet"
                    type="number"
                    value={profile.heightFeet}
                    onChange={handleChange}
                    sx={{ width: '48%' }}
                />
                <TextField
                    label="Inches"
                    name="heightInches"
                    type="number"
                    value={profile.heightInches}
                    onChange={handleChange}
                    sx={{ width: '48%' }}
                />
            </Box>

            {/* Height Preference */}
            <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Height Preference <span style={{ color: 'red' }}>*</span></InputLabel>
                <Select
                    name="heightPreference"
                    value={profile.heightPreference}
                    onChange={handleChange}
                    >
                    {heightPreferences.map((pref) => (
                        <MenuItem key={pref} value={pref}>{pref}</MenuItem>
                    ))}
                </Select>
            </FormControl>

            {/* Gender Selection */}
            <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Your Gender <span style={{ color: 'red' }}>*</span></InputLabel>
                <Select
                    name="gender"
                    value={profile.gender}
                    onChange={handleChange}
                    >
                    {genderOptions.map((gender) => (
                        <MenuItem key={gender} value={gender}>{gender}</MenuItem>
                    ))}
                </Select>
            </FormControl>

            {/* Sexual Preference Selection */}
            <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Sexual Preference <span style={{ color: 'red' }}>*</span></InputLabel>
                <Select
                    name="sexualPreference"
                    value={profile.sexualPreference}
                    onChange={handleChange}
                    >
                    {sexualPreferences.map((preference) => (
                        <MenuItem key={preference} value={preference}>{preference}</MenuItem>
                    ))}
                </Select>
            </FormControl>

            {/* Personality type selection */}
            <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Personality Type <span style={{ color: 'red' }}>*</span></InputLabel>
                <Select
                  name="personalityType"
                  value={profile.personalityType}
                  onChange={handleChange}
                >
                  {personalityTypes.map((type) => (
                    <MenuItem key={type} value={type}>{type}</MenuItem>
                  ))}
                </Select>
            </FormControl>

            {/* Party goer selection */}
            <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Party Goer <span style={{ color: 'red' }}>*</span></InputLabel>
                <Select
                  name="isPartyGoer"
                  value={profile.isPartyGoer}
                  onChange={handleChange}
                >
                  {partyOptions.map((option) => (
                    <MenuItem key={option} value={option}>{option}</MenuItem>
                  ))}
                </Select>
            </FormControl>

              {/* Likes pets selection */}
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Likes Pets <span style={{ color: 'red' }}>*</span></InputLabel>
                <Select
                  name="likesPets"
                  value={profile.likesPets}
                  onChange={handleChange}
                >
                  {petOptions.map((option) => (
                    <MenuItem key={option} value={option}>{option}</MenuItem>
                  ))}
                </Select>
            </FormControl>

              {/* Favorite animal selection */}
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Favorite Animal <span style={{ color: 'red' }}>*</span></InputLabel>
                <Select
                  name="favoriteAnimal"
                  value={profile.favoriteAnimal}
                  onChange={handleChange}
                >
                  {favoriteAnimals.map((animal) => (
                    <MenuItem key={animal} value={animal}>{animal}</MenuItem>
                  ))}
                </Select>
            </FormControl>

            {/* Additional questions */}
            <Typography variant="h6" gutterBottom>
              Additional Questions <span style={{ color: 'red' }}>*</span>
            </Typography>
            <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Hobby <span style={{ color: 'red' }}>*</span></InputLabel>
                <Select
                  name="hobby"
                  value={profile.additionalQuestions.hobby}
                  onChange={handleAdditionalChange}
                >
                  {hobbies.map((hobby) => (
                    <MenuItem key={hobby} value={hobby}>{hobby}</MenuItem>
                  ))}
                </Select>
            </FormControl>

            <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Best Describe Yourself <span style={{ color: 'red' }}>*</span></InputLabel>
                <Select
                  name="bestDescribe"
                  value={profile.additionalQuestions.bestDescribe}
                  onChange={handleAdditionalChange}
                >
                  {descriptions.map((desc) => (
                    <MenuItem key={desc} value={desc}>{desc}</MenuItem>
                  ))}
                </Select>
            </FormControl>

            {/* Submit button */}
            <Button variant="contained" color="primary" fullWidth onClick={handleSubmit} sx={{ mt: 2 }}>
              Submit
            </Button>
        </Box>
    );
}

export default ProfileSetup;