import React, { useState } from 'react';
import { Box, TextField, Button, Typography, Modal, Link } from '@mui/material';
import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail} from 'firebase/auth';
import { auth, db } from '../firebase';
import { useNavigate } from 'react-router-dom'; // Import useHistory for navigation
import { getDoc, doc } from 'firebase/firestore';
function LoginModal({ open, onClose, onRegisterOpen }) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [isForgotPassword, setIsForgotPassword] = useState(false);
    const history = useNavigate(); // Initialize useHistory

    const handleLogin = async () => {
        // Handle Login Logic
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
            console.log('Logged in: ', user);

            // Redirect to profile setup or dashboard
            // Check if the user has set up their profile
            const userRef = doc(db, 'users', user.uid);
            console.log('userRef exists: ', userRef.user);
            const userSnap = await getDoc(userRef);
            //console.log('userSnap exists: ', userSnap.exists());
            if(userSnap.exists()) {
                const userData = userSnap.data();
                if(userData.profile) {
                    // Redirect to profile page
                    history('/profile');
                    console.log('1 Profile already setup');
                } else {
                    // Redirect to profile setup page
                    history('/profile-setup');
                    console.log('2Profile Not yet setup');
                }
            } else {
                // Redirect to profile setup page if no user data found
                history('/profile-setup');
                console.log('3Profile Not yet setup');
            }
        } catch (error) {
            setError('Login failed. Please check your credentials.');
        }
    };

    // If user forgets password
    const handleForgotPassword = async () => {
        if (!email) {
            setError('Please enter your email to reset the password');
            return;
        }
        try {
            await sendPasswordResetEmail(auth, email);
            setError('Password reset email sent.');
        } catch (error) {
            setError('Error sending password reset email');
        }
    };

    const toggleForgotPassword = () => {
        setIsForgotPassword(true);
    };

    return (
        <Modal open={open} onClose={onClose} align="center">
            <Box sx={{ p:4, maxWidth: 400, mx:'auto', mt: 8, backgroundColor: 'white', borderRadius: 2,
            marginLeft: '2%', marginRight: '2%'}}>
                <Typography variant="h4" align="center" gutterBottom>
                    { isForgotPassword ? 'Reset Password' : 'Login'}
                </Typography>
                {error && <Typography color="error">{error}</Typography>}
                <TextField
                    label="Email"
                    type="email"
                    fullWidth
                    margin="normal"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    />
                {!isForgotPassword && (
                <TextField
                    label="Password"
                    type="password"
                    fullWidth
                    margin="normal"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    />
                    )}
                <Button variant="contained" color="primary" fullWidth sx={{ mt: 2, backgroundColor: 'brown' }}
                 onClick={isForgotPassword ? handleForgotPassword : handleLogin}>
                    {isForgotPassword ? 'Reset Password' : 'Login'}
                </Button>
                {!isForgotPassword && (
                    <Button onClick={toggleForgotPassword} sx={{ mt: 1 }} fullWidth>
                        Forgot Password?
                    </Button>
                    )}
                <Typography variant="body2" align="center" sx={{ mt: 2 }}>
                    <Link component="button" variant="body2" onClick={ onRegisterOpen }>
                        Register here
                    </Link>
                </Typography>
            </Box>
        </Modal>
    );
}

export default LoginModal;