/**
 * TODO:
 * Make sure the notifications bell show badge number and increment when speedDateRequest is sent as well
 */
import React, { useState, useEffect } from 'react';
import {Box, Typography, Button, Avatar, Card, CardContent, Grid, IconButton, Badge, Modal, TextField } from '@mui/material';
import { getAuth } from 'firebase/auth';
import {
    getFirestore,
    doc,
    getDoc,
    getDocs,
    onSnapshot,
    collection,
    query,
    where,
    or,
    and,
    updateDoc,
    addDoc, serverTimestamp
} from "firebase/firestore";
import { auth, db } from '../firebase';
import { useParams } from 'react-router-dom';
import NotificationsIcon from '@mui/icons-material/Notifications';
import EditIcon from '@mui/icons-material/Edit'; // Import pen
import FavoriteIcon from '@mui/icons-material/FavoriteBorder';
import SettingsIcon from '@mui/icons-material/Settings';
import Popup from "../components/Popup";
import NotificationModal from '../components/NotificationModal';
import SpeedDateModal from "../components/SpeedDateModal";
import { useNavigate } from 'react-router-dom';
import SpeedDatePage from "../components/SpeedDatePage";

function Profile() {
    // State to hold the user data
    const { userId } = useParams(); // Get the userId from the route
    const [user, setUser] = useState(null);
    const [notificationCount, setNotificationCount] = useState(0);
    const [speedNotificationCount, setSpeedNotificationCount] = useState(0);
    const [notificationModalOpen, setNotificationModalOpen] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('');
    // Track if the notification has been shown
    const [notificationShown, setNotificationShown] = useState(false);
    // Track state for profilePicModal
    const [profilePicModalOpen, setProfilePicModalOpen] = useState(false); // For controlling the profile pic modal
    // Track state of edit profile modal
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [heartClickPopupOpen, setHeartClickPopupOpen] = useState(false);
    // State to control feedback modal
    const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedProfile, setSelectedProfile] = useState(null);
    const [feedback, setFeedback] = useState('');
    const [heartClickMessage, setHeartClickMessage] = useState('');
    const [updatedProfile, setUpdatedProfile] = useState({
        age: '',
        heightFeet: '',
        heightInches: '',
        city: '',
        region: '',
        profilePicture: ''
    });
    const [likesCount, setLikesCount] = useState(0);
    const [matchesCount, setMatchesCount] = useState(0);
    const navigate = useNavigate();
    const currentUser = getAuth().currentUser?.uid;
    const currentUserEmail = getAuth().currentUser?.email;

    // Check if the user is an admin
    const isAdmin = currentUserEmail === 'blackdateceo@gmail.com';

    // Function to check and reset RizzUp and SpeedDate limits in realtime
    const checkAndResetLimits = async () => {
        console.log("Profile: In checkAndResetLimits");
        const userRef = doc(db, 'users', currentUser);
        const userSnap = await getDoc(userRef);
        const userData = userSnap.data();

        if (userData) {
            // Get current time
            const now = new Date();
            const lastRizzUpReset = userData.profile.lastRizzUpReset ? userData.profile.lastRizzUpReset.toDate() : null;
            const lastSpeedDateReset = userData.profile.lastSpeedDateReset ? userData.profile.lastSpeedDateReset.toDate() : null;

            // Reset daily RizzUp requests if 24 hours have passed
            if (lastRizzUpReset && (now - lastRizzUpReset) >= 24 * 60 * 60 * 1000) {
                // Update the rizzUp Reset Time and rizzUps
                await updateDoc(userRef, {
                    'profile.remainingRizzUpRequests': 2,
                    'profile.lastRizzUpReset': now
                });
            }

            // Reset weekly SpeedDate requests if 7 days have passed
            if (lastSpeedDateReset && (now - lastSpeedDateReset) >= 7 * 24 * 60 * 60 * 1000) {
                await updateDoc(userRef, {
                    'profile.remainingSpeedDateRequests': 2,
                    'profile.lastSpeedDateReset': now
                });
            }
        }
    };

    // Function to count how many likes a profile has in realtime by calling it inside useEffect
    const countProfileLikes = async () => {
        try {
            // Query the likes collection where 'receiver' equals current user's UID
            const likesRef = collection(db, 'likes');
            const q = query(likesRef, where('receiver', '==', currentUser))
            const querySnapshot = await getDocs(q);

            // Count the number of likes
            setLikesCount(querySnapshot.size);
        } catch (error) {
            console.error('Profile: Error fetching likes :', error);
        }
    };

    // Function to count how many matches a user has
    const countProfileMatches = async () => {

        try {
            // Query the requests collection where 'sender' or 'receiver' == currentUser UID and status is accepted
            const requestsRef = collection(db, 'requests');
            const q = query(
                requestsRef,
                and(
                    or(
                        where('sender', '==', currentUser),
                        where('receiver', '==', currentUser)
                    ),
                where('status', '==', 'accepted')
                )
            );

            // Fetch the query snapshot
            const querySnapshot = await getDocs(q);

            // Count the number of matched requests
            setMatchesCount(querySnapshot.size);

            console.log('Profile: countProfileMatches = ', matchesCount);
        } catch (error) {
            console.error('Profile: Error counting matches: ', error);
        }
    };

    // Fetch user data and set up notifications listener
    useEffect(() => {
        const fetchUserData = async () => {
            // Determine the user ID to fetch: either from the URL params or the current authenticated user
            const uidToFetch = userId || currentUser;
            console.log('Profile: currentUser ID = ', currentUser);

            if(uidToFetch) {
                // Reference to the user's document in Firestore
                const userRef = doc(db, 'users', uidToFetch);
                // Get the user's document
                const userSnap = await getDoc(userRef);

                if(userSnap.exists()) {
                    // Set the user data to state
                    setUser({...userSnap.data(), uid:userSnap.id });
                } else {
                    console.log('Profile: No such user document found');
                }

                // Check if user checking their own profile page or not
                if (uidToFetch === currentUser) {
                    // Set up a Firestore listener to count the number of pending requests (notifications)
                    const notificationRef = collection(db, 'requests');
                    const q = query(notificationRef, where('receiver', '==', uidToFetch), where('status', '==', 'pending'));
                    // To get the number of pending speedDateRequests
                    const speedDateRef = collection(db, 'speedDateRequests');
                    const qSpeedDate = query(speedDateRef, where('receiver', '==', uidToFetch), where('status', '==', 'pending'));

                    // To store number of notifications
                    let notificationNum = 0;

                    // Listener For requests
                    const unsubscribe = onSnapshot(q, (querySnapshot) => {
                        setNotificationCount(querySnapshot.size);
                        // Check if there are new notifications and if they haven't been shown yet
                        if (querySnapshot.size > 0 && !notificationShown && !localStorage.getItem('notificationShown')) {
                            const latestRequest = querySnapshot.docs[0].data();
                            setNotificationMessage(`RizzUp request from ${latestRequest.senderUsername}`);
                            setNotificationModalOpen(true);
                            // Set notification as shown in state and local storage
                            setNotificationShown(true);
                            localStorage.setItem('notificationShown', 'true');
                        }
                    });

                    // Real Time Listener for SpeedDate requests
                    const unsubscribeSpeed = onSnapshot(qSpeedDate, (querySnapshot) => {
                        setSpeedNotificationCount(querySnapshot.size);
                        console.log("Profile: notificationNum Speed = ", querySnapshot.size);
                        // Check if there are new notifications and if they haven't been shown yet
                        /*if (querySnapshot.size > 0 && !notificationShown && !localStorage.getItem('notificationShown')) {
                            const latestSpeedRequest = querySnapshot.docs[0].data();

                        }*/
                    });


                    console.log('Profile: Notification Count = ', notificationCount);
                    return () => {
                        unsubscribe();
                        unsubscribeSpeed();
                    }
                }
            }
        };

        // Call checkAndResetLimits to ensure limits are updated correctly
        if (currentUser) {
            checkAndResetLimits();
            // Also count Profile likes
            countProfileLikes();
            // Also count how many matches a user has
            countProfileMatches();
        }

        fetchUserData();
    }, [userId, currentUser, notificationShown]); // Include notificationShown in dependency array

    // Function to navigate user to Dashboard after clicking Notification button
    const handleNotificationClick = () => {
        navigate('/dashboard');
    };

    // Function to navigate user to Matches page
    const handleViewMatchesClick = () => {
        // Navigate to Matches
        navigate('/matches');
    }

    // Function to handle speed date button being clicked
    const handleSpeedDate = (profile) => {
        setSelectedProfile(profile);
        setModalOpen(true);
    };

        // Function to submit speed date proposal
    const handleSubmitSpeedDate = async (proposal) => {
        //console.log(`Proposed speed date with ${selectedProfile.name} on ${proposal.date} at ${proposal.time}`);
        // Add functionality to send the proposal and handle acceptance
        if (!currentUser) {
            console.log('Link: User is not authenticated');
            return;
        }
         //console.log(`Proposed speed date with ${selectedProfile.name} on ${proposal.date} at ${proposal.time}`);
        // Add functionality to send the proposal and handle acceptance
        // Fetch the current user's profile to get the username
        const userRef = doc(db, 'users', currentUser);
        const userSnap = await getDoc(userRef);
        const userData = userSnap.data();

        if(!userData || !userData.profile || !userData.profile.username) {
            console.log('Current user profile data is missing');
            return;
        }

        // Check if user has speedDate requests first
        if (userData.profile.remainingSpeedDateRequests > 0) {
            try {

                const senderUsername = userData.profile.username;

                // Check if a speed date request already exists before sending one
                const speedDateRequestRef = collection(db, 'speedDateRequests');
                const q = query(speedDateRequestRef, where('sender', '==', currentUser),
                    where('receiver', '==', selectedProfile.uid));
                const querySnapshot = await getDocs(q);

                if (!querySnapshot.empty) {
                    console.log('Link: Speed date request already sent to this user');
                    setHeartClickMessage('There is a pending speedDate request already');
                    setHeartClickPopupOpen(true);
                    return;
                }

                await addDoc(speedDateRequestRef, {
                    completed: false,
                    sender: currentUser,
                    senderUsername,
                    receiver: selectedProfile.uid,
                    status: 'pending',
                    date: proposal.date,
                    time: proposal.time,
                    timestamp: new Date()
                });

                // Deduct one SpeedDate request
                await updateDoc(userRef, {
                    'profile.remainingSpeedDateRequests': userData.profile.remainingSpeedDateRequests - 1
                });

                //setNotificationMessage(`SpeedDate request sent to ${selectedProfile.username}`);
                //setNotificationModalOpen(true);

                setHeartClickMessage('⏰ SpeedDate Request Sent!');
                setHeartClickPopupOpen(true);
                console.log(`Link: Speed date request sent to ${selectedProfile.username}`);
            } catch (error) {
                console.error('Link: Error sending speed date request', error);
            }
        } else {
            // Show error snackbar if the user has run out of SpeedDate requests
            setHeartClickMessage('You have run out of SpeedDate requests for this week.');
            setHeartClickPopupOpen(true);
        }
    };



    // Function to handle opening the edit modal and pre-populating fields
    const handleEditProfile = () => {
        setUpdatedProfile({
            age: user.profile.age,
            heightFeet: user.profile.heightFeet,
            heightInches: user.profile.heightInches,
            city: user.profile.city,
            region: user.profile.region,
            profilePicture: user.profile.profilePicture
        });
        setIsEditModalOpen(true);
    };

    // Function to handle updating profile details in Firestore
    const handleSaveProfile = async () => {
        const userRef = doc(db, 'users', currentUser);
        await updateDoc(userRef, {
            'profile.age': updatedProfile.age,
            'profile.heightFeet': updatedProfile.heightFeet,
            'profile.heightInches': updatedProfile.heightInches,
            'profile.city': updatedProfile.city,
            'profile.region': updatedProfile.region,
            'profile.profilePicture': updatedProfile.profilePicture
        });
        setIsEditModalOpen(false);
        setUser(prev => ({
            ...prev,
            profile: { ...prev.profile, ...updatedProfile}
        }));
    };

    // Function to handle changes in input fields
    const handleChange = (e) => {
        const { name, value } = e.target;
        setUpdatedProfile(prev => ({
            ...prev,
            [name]: value
        }));
    };

    // Modal styling
    const modalStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        p: 4,
        marginTop: '40%',
        marginLeft: '5%',
        marginRight: '5%',
        backgroundColor: 'white',
        transform: 'translateY(-50%)',
        borderRadius: '10px',
        '@media (max-width: 768px)': {
            marginTop: '70%', // This applies to screens below 768px width
        }
    };

    // Function to handle popup when user clicks their heart
    const handleHeartClick = () => {
        setHeartClickMessage(`${likesCount} people have ❤️ your profile`);
        setHeartClickPopupOpen(true);
    }

    // Redirect to the profile Upload page
    const handleProfilePictureUpload = () => {
        navigate('/profile-picture-upload');
    };

    // Function to handle feedback submission
    const handleSendFeedback = async () => {
        if (feedback) {
            await addDoc(collection(db, 'feedback'), {
                userId: currentUser,
                username: user.profile.username,
                email: user.email,
                message: feedback,
                timestamp: serverTimestamp(),
            });
            setFeedback('');
            alert('Feedback submitted successfully!');
        }
    };

    // To handle Logging out user
    const handleLogout = () => {
        getAuth().signOut().then(() => {
            // Navigate to home page
            navigate('/');
        }).catch((error) => {
            console.error("Logout error: ", error);
        });
    };

    if(!user) {
        return <Typography variant="h6" align="center">Loading...</Typography>
    }

    const isCurrentUser = userId ? userId === currentUser : true;

    return (
        <Box sx={{ p: 4 }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
                {/* Pen Icon on the left for editing profile pic */}
                { isCurrentUser && (
                    <Badge badgeContent={likesCount} color="secondary"
                        sx={{
                            '& .MuiBadge-badge': {
                                fontSize: '1rem',
                                top: '5%',
                                right: '13%',
                            }
                        }}
                    >
                         <IconButton onClick={handleHeartClick}>
                            <FavoriteIcon color="error" sx={{ width: '48px', height: '48px', marginBottom: 'auto' }}/>
                         </IconButton>
                    </Badge>
                )}
                { isCurrentUser && (
                    <IconButton color="inherit" onClick={() => setProfilePicModalOpen(true)}>
                        <EditIcon />
                    </IconButton>
                )}
                {/*Avatar from Material-UI to display image in circular shape*/}
                <Avatar
                    alt={user.name}
                    // Use the profilePicture URL from Firestore
                    src={user.profilePicture}
                    sx={{ width: 150, height: 150, borderRadius: '50%', border: '4px solid #FFFD700' }}
                    />
                { isCurrentUser && (
                        <IconButton color="inherit" onClick={handleNotificationClick}>
                            <Badge badgeContent={notificationCount + speedNotificationCount} color="secondary">
                                <NotificationsIcon />
                            </Badge>
                        </IconButton>
                )}
                { isCurrentUser && (
                    <IconButton>
                        <FavoriteIcon color="error" sx={{ width: '48px', height: '48px', marginBottom: 'auto', visibility: 'hidden' }}/>
                    </IconButton>
                )}
            </Box>
            <Typography variant="h4" align="center" gutterBottom>
                {user.profile.username}, {user.profile.age}
            </Typography>
            <Typography variant="h6" align="center" color="text.secondary" gutterBottom>
                {user.profile.city}, {user.profile.region}
            </Typography>
            <Card sx={{ mt: 4 }}>
                <CardContent>
                    <Typography variant="h6" gutterBottom>
                        Common Interests
                    </Typography>
                    <Grid container spacing={2}>
                        {user.profile.commonInterests.map((interest, index) => (
                            <Grid item key={index} xs={6}>
                                <Typography variant="body1">{interest}</Typography>
                            </Grid>
                        ))}
                    </Grid>
                </CardContent>
            </Card>
            { isCurrentUser && (
                <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-around'}}>
                    <Button variant="contained" color="primary" onClick={handleEditProfile}>
                        Edit Profile
                    </Button>
                    <Button variant="outlined" color="secondary" onClick={handleViewMatchesClick}>
                        { matchesCount } Matches
                    </Button>
                    <Button variant="outlined" color="error" onClick={handleLogout}>
                        Logout
                    </Button>
                </Box>
            )}
            { isCurrentUser && (
                <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-around'}}>
                    <Typography variant="body1">
                        Remaining RizzUp Requests: {user.profile.remainingRizzUpRequests}
                    </Typography>
                    <Typography variant="body1">
                        Remaining SpeedDate Requests: {user.profile.remainingSpeedDateRequests}
                    </Typography>
                </Box>
            )}
            { isAdmin && (
                <center><Button variant="contained" sx={{ backgroundColor: 'brown' }} onClick={() => navigate('/admin')}>
                    Admin Dashboard
                </Button></center>

            )}
            { isCurrentUser && (
                <Box sx={{ position: 'fixed', bottom: '64px', right: '16px', height: '32px', width: '32px' }}>
                    <IconButton onClick={() => setFeedbackModalOpen(true)}>
                        <SettingsIcon />
                    </IconButton>
                </Box>
            )}
            { !isCurrentUser && (
                <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center'}}>
                    <Button variant="outlined" sx={{ borderColor: 'brown', color: 'brown' }} onClick={() => handleSpeedDate(user)}>
                        Send SpeedDate Request
                    </Button>
                </Box>
            )}
            <NotificationModal
                open={notificationModalOpen}
                onClose={() => setNotificationModalOpen(false)}
                message={notificationMessage}
                />

            {/* Edit Profile Modal */}
            <Modal open={isEditModalOpen} onClose={() => setIsEditModalOpen(false)}>
                <Box sx={{ ...modalStyle }}>
                    <Typography variant="h6" gutterBottom>
                        Edit Profile
                    </Typography>
                    <TextField label="Age" name="age" value={updatedProfile.age} onChange={handleChange} fullWidth />
                    <TextField label="Height (Feet)" name="heightFeet" value={updatedProfile.heightFeet} onChange={handleChange} fullWidth />
                    <TextField label="Height (Inches)" name="heightInches" value={updatedProfile.heightInches} onChange={handleChange} fullWidth />
                    <TextField label="City" name="city" value={updatedProfile.city} onChange={handleChange} fullWidth />
                    <TextField label="Region" name="region" value={updatedProfile.region} onChange={handleChange} fullWidth />

                    <Button variant="contained" onClick={handleSaveProfile} sx={{ mt: 2, backgroundColor: 'brown' }}>Save</Button>
                </Box>
            </Modal>

            {/* Modal for profile picture change */}
            <Modal open={profilePicModalOpen} onClose={() => setProfilePicModalOpen(false)}>
                <Box sx={{ ...modalStyle }}>
                    <Typography variant="h6">Change Profile Picture</Typography>
                    <Button variant="outlined" color="primary" onClick={handleProfilePictureUpload} sx={{ mt: 2 }}>
                        Go to Change Profile Picture
                    </Button>
                </Box>
            </Modal>

            {/* Modal for user feedback */}
            <Modal open={feedbackModalOpen} onClose={() => setFeedbackModalOpen(false)}>
                <Box sx={{ ...modalStyle }}>
                    <Typography variant="h6">Send Feedback/Issue</Typography>
                    <TextField
                        label="Your Feedback"
                        value={feedback}
                        conChange={(e) => setFeedback(e.target.value)}
                        fullWidth
                        multiline
                        rows={4}
                        sx={{ mt: 2 }}
                        />
                    <Button variant="contained" onClick={handleSendFeedback} sx={{ mt: 2, backgroundColor: 'brown' }}>
                        Send
                    </Button>

                    {/* Footer for copyright notice */}
                    <Box sx={{ textAlign: 'center', marginTop: 4 }}>
                        <Typography variant="body2">
                            &copy; {new Date().getFullYear()} Copyright: Anesu Maxwell Matanga
                        </Typography>
                    </Box>
                </Box>
            </Modal>

            {/* Popup messages */}
            <Popup
                open={heartClickPopupOpen}
                onClose={() => setHeartClickPopupOpen(false)}
                message={heartClickMessage}
                />

            {/* SpeedDate modal */}
            {selectedProfile && (
                <SpeedDateModal
                    open={modalOpen}
                    onClose={() => setModalOpen(false)}
                    onSubmit={handleSubmitSpeedDate}
                    profile={selectedProfile}
                    />
            )}
        </Box>
    );
}

export default Profile;